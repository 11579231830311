<!-- <template>
    <div class="pageContainer jxjTypePage flexCloumn">
        <init></init>
    </div>
</template>

<script>
export default {
    components: {
        init: () => {
            return import("../../views/pages/init.vue")
        }
    },
}
</script> -->

<template>
    <div class="pageContainer flexCenter" style="width:100%;">
        <div class="flexCloumnCenter" style="width:100%;">
            <img :src="staticImgs.todo" alt="">
            <div class="">
                敬请期待
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            staticImgs: {},

        };
    },
    mounted() {
        let _this = this
        _this.getimgs();
    },
    methods: {
        getimgs() {
            let _this = this
            let staticImgs = {}
            this.$http.post("/key_value/list", {
                types: 'xg_web_img',
                page: 1, pagesize: 1000
            }).then(res => {
                if (res && res.data.data && res.data.data.items && res.data.data.items.length > 0) {
                    res.data.data.items.map(a => {
                        staticImgs[a.key] = ''
                        staticImgs[a.key] = a.value
                        this.staticImgs = staticImgs
                    })

                }
            })
        },
    }
}
</script>
